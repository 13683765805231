<template>
  <v-container>
    <v-row>
      <v-col cols="12"
        ><PageTitleSearchCreate
          :page-title="!$language ? 'Projects' : 'প্রজেক্ট সমূহ'"
          :placeholder="
            !$language
              ? 'Search By Project Name'
              : 'প্রজেক্টের নাম দিয়ে সার্চ করুন'
          "
          :create-btn="!$language ? 'Add Project' : 'প্রজেক্ট তৈরী করুন'"
          @createEvent="$router.push({ name: 'add-project' })"
        ></PageTitleSearchCreate
      ></v-col>
      <template v-if="fetchingContent">
        <div id="loading-wrapper">
          <div id="loading-text">LOADING</div>
          <div id="loading-content"></div>
        </div>
      </template>
      <no-content-component
        v-else-if="networkError"
        :message="networkErrorNoContentMessage"
      ></no-content-component>
      <no-content-component
        v-else-if="noContentCondition"
        :message="noContentMessage"
      ></no-content-component>
      <v-col
        v-else
        v-for="(project, i) in projects"
        :key="i"
        xl="4"
        lg="6"
        md="12"
        sm="12"
        class="m-4"
      >
        <MProjectListItem
          :project="project"
          @share="shareProject(i)"
          @download="download(project.object_id)"
          @delete="showDeleteBatchDialog"
        ></MProjectListItem>
      </v-col>
      <v-container v-if="shareProjectPanel">
        <ProjectSharePanel
          :project-to-share="projectToShare"
          :sharing="sharing[projectToShare.object_id]"
          :should_show_loading_icon="should_show_loading_icon"
          :share-panel="shareProjectPanel"
          @closePanel="closeProjectPanel"
          @shareProject="submitFormData"
        ></ProjectSharePanel>
      </v-container>
    </v-row>
    <v-dialog v-model="upload_dialog_visible" width="600px">
      <m-project-upload
        :on-cancel="hideUploadDialog"
        :on-save="uploadProject"
        :saving="saving"
      ></m-project-upload>
    </v-dialog>

    <dialog-delete
      v-model="projectDeleteDialog"
      :item="projectToDelete"
      :item-text="!$language ? 'Project' : 'প্রজেক্ট'"
      :deleting="deleting"
      @confirm-remove="removeProject"
    ></dialog-delete>
  </v-container>
</template>

<script>
import _ from "lodash";
import projectService from "#ecf/project/services/ProjectWorkService";
import batchService from "#ecf/batch/services/BatchService";
import MProjectUpload from "@ecf/routes/project/ProjectUpload";
import PageTitleSearchCreate from "@ecf/global/components/PageTitleSearchCreate";
import MProjectListItem from "#ecf/project/components/MProjectListItem";
import ProjectSharePanel from "#ecf/project/components/ProjectSharePanel";
import DialogDelete from "@ecf/global/components/DialogDelete";
import { delayPromise } from "/global/utils/helpers";
import NoContentComponent from "/global/components/NoContentComponent";

export default {
  name: "ProjectListPage",
  components: {
    DialogDelete,
    ProjectSharePanel,
    MProjectListItem,
    PageTitleSearchCreate,
    MProjectUpload,
    NoContentComponent,
  },
  props: {
    courseId: String,
  },
  data() {
    return {
      fetchingContent: true,
      upload_dialog_visible: false,
      saving: false,
      batches: [],
      shareProjectPanel: false,
      projectsData: [],
      project_shares: {},
      projectToShare: undefined,
      share_idx: undefined,
      sharing: {},
      projectDeleteDialog: false,
      projectToDelete: undefined,
      deleting: false,
      should_show_loading_icon: false,
      networkError: false,
    };
  },
  computed: {
    projects() {
      return this.projectsData.map((a) => {
        const shares = this.project_shares[a.object_id]
          ? this.project_shares[a.object_id]
          : {};
        const batches = this.batches.map((b) => {
          if (shares[b.batch_id]) {
            return Object.assign({}, shares[b.batch_id], {
              batch_title: b.batch_title,
              shared: true,
            });
          } else {
            return {
              batch_title: b.batch_title,
              batch_id: b.batch_id,
              shared: false,
            };
          }
        });
        this.$set(a, "batches", batches);
        const shared_batches = a.batches
          .filter((b) => b.shared)
          .map((b) => b.batch_title);
        this.$set(a, "shared_batches", shared_batches);
        return a;
      });
    },
    noContentCondition() {
      return this.projects?.length == 0;
    },
    noContentMessage() {
      return !this.$language
        ? "You currently have no projects"
        : "বর্তমানে আপনার কোনো প্রজেক্ট নেই";
    },
    networkErrorNoContentMessage() {
      return !this.$language
        ? "Your projects are currently not available"
        : "বর্তমানে আপনার প্রজেক্ট সংক্রান্ত তথ্য এনে দেয়া সম্ভব হচ্ছে না";
    },
  },
  async created() {
    const isOnline = window.navigator.onLine;
    if (!isOnline) {
      this.$root.$emit("alert", [
        "Alert",
        "There might be an internet connection issue on your system",
      ]);
      this.networkError = true;
      this.fetchingContent = false;
      return;
    }
    this.fetchingContent = true;
    await this.fetchBatches();
    await this.fetchProjects();
    this.fetchingContent = false;
  },
  methods: {
    closeProjectPanel() {
      this.shareProjectPanel = false;
    },
    async submitFormData(formData) {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system",
        ]);
        return;
      }
      if (this.should_show_loading_icon) return;
      try {
        let finalFormData = [];
        this.should_show_loading_icon = true;
        if (Object.keys(formData).length > 0) {
          for (var formKey in formData) {
            this.$set(formData[formKey], "course_id", this.courseId);
            this.$set(formData[formKey], "object_type", "project");
            this.$set(
              formData[formKey],
              "object_id",
              this.projectToShare.object_id
            );
            this.$set(
              formData[formKey],
              "partner_code",
              this.$route.params.partner_code
            );
            finalFormData.push(formData[formKey]);
          }
          await projectService.shareMultiple(finalFormData);
          await this.fetchProjects();
        } else {
          this.$set(formData, "course_id", this.courseId);
          this.$set(formData, "object_type", "project");
          this.$set(formData, "object_id", this.projectToShare.object_id);
          this.$set(formData, "partner_code", this.$route.params.partner_code);
          await projectService.deleteMultiple(formData);
          await this.fetchProjects();
        }
        this.shareProjectPanel = false;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.should_show_loading_icon = false;
      }
    },

    async fetchProjects() {

      const isOnline = window.navigator.onLine;
      if ( !isOnline ) {
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        return;
      }
      try {
        this.projectsData = await projectService.find(this.courseId);
        this.projectsData.forEach((a) => {
          projectService
            .getSharedBatches(a.object_id, this.courseId)
            .then((shares) => {
              const share_map = _.fromPairs(shares.map((s) => [s.batch_id, s]));
              this.$set(this.project_shares, a.object_id, share_map);
            });
        });
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          JSON.stringify(e.response ? e.response.data : e.message)
        ]);
      }
    },
    async fetchBatches() {
      try {
        this.batches = await batchService.find(this.courseId);
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          JSON.stringify(e.response ? e.response.data : e.message)
        ]);
      }
    },
    async uploadProject(projectServiceData) {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system",
        ]);
        return;
      }
      if (this.saving) return;
      try {
        this.saving = true;
        await projectService.save(this.courseId, projectServiceData);
        await delayPromise(3000);
        await this.fetchProjects();
        this.hideUploadDialog();
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          JSON.stringify(e.response ? e.response.data : e.message),
        ]);
      } finally {
        this.saving = false;
      }
    },
    async download(projectService_id) {
      try {
        const project = await projectService.get(
          projectService_id,
          this.courseId
        );
        const url = project.url;
        window.open(url);
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          JSON.stringify(e.response ? e.response.data : e.message),
        ]);
      }
    },

    hideUploadDialog() {
      this.upload_dialog_visible = false;
    },

    shareProject(idx) {
      this.shareProjectPanel = true;
      this.share_idx = idx;
      this.projectToShare = this.projects[idx];
    },

    showDeleteBatchDialog(project) {
      this.projectDeleteDialog = true;
      this.projectToDelete = project;
    },

    async removeProject() {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system",
        ]);
        return;
      }
      if (this.deleting) return;
      try {
        this.deleting = true;
        await projectService.delete(this.projectToDelete, this.courseId);
        await this.fetchProjects();
        this.hideDeleteDialog();
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.deleting = false;
      }
    },

    hideDeleteDialog() {
      this.projectDeleteDialog = false;
    },
  },
};
</script>
