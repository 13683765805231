var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("PageTitleSearchCreate", {
                attrs: {
                  "page-title": !_vm.$language ? "Projects" : "প্রজেক্ট সমূহ",
                  placeholder: !_vm.$language
                    ? "Search By Project Name"
                    : "প্রজেক্টের নাম দিয়ে সার্চ করুন",
                  "create-btn": !_vm.$language
                    ? "Add Project"
                    : "প্রজেক্ট তৈরী করুন",
                },
                on: {
                  createEvent: function ($event) {
                    return _vm.$router.push({ name: "add-project" })
                  },
                },
              }),
            ],
            1
          ),
          _vm.fetchingContent
            ? [
                _c("div", { attrs: { id: "loading-wrapper" } }, [
                  _c("div", { attrs: { id: "loading-text" } }, [
                    _vm._v("LOADING"),
                  ]),
                  _c("div", { attrs: { id: "loading-content" } }),
                ]),
              ]
            : _vm.networkError
            ? _c("no-content-component", {
                attrs: { message: _vm.networkErrorNoContentMessage },
              })
            : _vm.noContentCondition
            ? _c("no-content-component", {
                attrs: { message: _vm.noContentMessage },
              })
            : _vm._l(_vm.projects, function (project, i) {
                return _c(
                  "v-col",
                  {
                    key: i,
                    staticClass: "m-4",
                    attrs: { xl: "4", lg: "6", md: "12", sm: "12" },
                  },
                  [
                    _c("MProjectListItem", {
                      attrs: { project: project },
                      on: {
                        share: function ($event) {
                          return _vm.shareProject(i)
                        },
                        download: function ($event) {
                          return _vm.download(project.object_id)
                        },
                        delete: _vm.showDeleteBatchDialog,
                      },
                    }),
                  ],
                  1
                )
              }),
          _vm.shareProjectPanel
            ? _c(
                "v-container",
                [
                  _c("ProjectSharePanel", {
                    attrs: {
                      "project-to-share": _vm.projectToShare,
                      sharing: _vm.sharing[_vm.projectToShare.object_id],
                      should_show_loading_icon: _vm.should_show_loading_icon,
                      "share-panel": _vm.shareProjectPanel,
                    },
                    on: {
                      closePanel: _vm.closeProjectPanel,
                      shareProject: _vm.submitFormData,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "600px" },
          model: {
            value: _vm.upload_dialog_visible,
            callback: function ($$v) {
              _vm.upload_dialog_visible = $$v
            },
            expression: "upload_dialog_visible",
          },
        },
        [
          _c("m-project-upload", {
            attrs: {
              "on-cancel": _vm.hideUploadDialog,
              "on-save": _vm.uploadProject,
              saving: _vm.saving,
            },
          }),
        ],
        1
      ),
      _c("dialog-delete", {
        attrs: {
          item: _vm.projectToDelete,
          "item-text": !_vm.$language ? "Project" : "প্রজেক্ট",
          deleting: _vm.deleting,
        },
        on: { "confirm-remove": _vm.removeProject },
        model: {
          value: _vm.projectDeleteDialog,
          callback: function ($$v) {
            _vm.projectDeleteDialog = $$v
          },
          expression: "projectDeleteDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }